import React, { Component } from 'react';
import logo from './logo2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



const style = {
  position: 'fixed',
  overflow: 'hidden',
  zIndex: 999999,
  background: 'white',
  bottom: '1%',
  left: 0,
  width: '30%',
  border: '1px solid #000',
  boxShadow: 24,
};

class ContentImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      recipesData: null,
      certifica: [],
      imageVisible: false,
      isMobile: window.innerWidth <= 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  _closeKvkk = () => {
    localStorage.setItem('KVKKCheck', true);
  };

  render() {
    const { isMobile } = this.state;

    return (
      <div style={style} className="p-4 bg-white shadow-lg rounded-lg w-full">
      <div className="flex flex-row items-center w-full">
        {!isMobile && (
          <div className="border-2 border-gray-200 rounded-lg w-14 h-14 p-2 mr-4">
            <img src={logo} alt="Logo" className="w-full h-full object-contain" />
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <p className="font-[Gotham-Medium] text-gray-800">
            Hizmet kalitesi için çerezleri kullanabiliriz.
          </p>
          <p className="font-[Gotham-Regular] text-gray-600">
            Meram Gelişim Akademisi'ne girerek kullanım izni vermiş sayılırsınız.
          </p>
        </div>
    
        <button
          onClick={() => this.props._closeKVKKVisible()}
          className="ml-auto bg-gradient-to-r from-[#2E7E9E] to-[#006890] hover:from-[#006890] hover:to-[#D08E38] text-white p-2 rounded-full"
        >
          <FontAwesomeIcon className="m-1" icon={faClose} />
        </button>
      </div>
    
      <div className="flex w-full mt-4 space-x-2">
        <button
          onClick={() => this.props._closeKVKKVisible()}
          className="bg-gradient-to-r from-[#2E7E9E] to-[#006890] hover:from-[#006890] hover:to-[#D08E38] w-full text-white py-2 rounded-md font-[PoppinsReg] text-[16px]"
        >
          Anladım
        </button>
        <Link
          to="/kvkk"
          className="bg-gray-100 w-full py-2 rounded-md text-center font-[Gotham-Medium] text-gray-700 text-[13px] hover:bg-gray-200"
        >
          Veri Politikamız
        </Link>
      </div>
    </div>
    
    );
  }
}

export default ContentImage;
