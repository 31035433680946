import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { isMobile } from "react-device-detect";

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



class Banner extends Component {
    constructor() {
        super()
        this.state = {
            open: true,
            open2: true,
            sliderData: []
        }
    }
    async componentDidMount() {
        await this._getSubCategory()
    }

    _getSubCategory = async () => {
        try {
            axios.get('https://getjob.stechomeyazilim.info:5101/getSliderMega/select/')
                .then((res) => {
                    this.setState({ sliderData: res.data })
                    this.props.setIsLoading(false);

                })
        }
        catch (error) {
            console.log("errorAS" + error)
        }
    }

    render() {
        const settings = {
            autoplay: {
                delay: 1500,
                disableOnInteraction: false,
            },
            speed: 500,
            grabCursor: true,
            watchSlidesProgress: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true
        }
        return (
            isMobile == true ?
        
            <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={20} // Mobilde daha az boşluk
            slidesPerView={1}  // Tek slayt gösterimi
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 5000 }} // 5 saniye otomatik geçiş
            navigation
            className="w-full h-full relative" // Yüksekliği buradan ayarlayın
          >
            {this.state.sliderData.map((item, i) => (
              <SwiperSlide key={i} className="swiper-slide slide-item relative"> 
                {/* Mobil Fotoğraf */}
                <img
                  src={`https://megasubdomain.stechomeyazilim.info/${item.Slider_MobilePhoto}`}
                  className="w-full h-full object-fill"  // Yüksekliği tam kapsama için h-full kullandık
                  alt=""
                />
          
                {/* Overlay Efekti */}
                <div className="absolute inset-0 bg-black opacity-30"></div> {/* Overlay için arka plana hafif bir renk ekleyin */}
                
                {/* İçerik */}
                <div className="absolute inset-0 flex flex-col justify-end items-center text-center p-4">
                  <h2 className="text-white text-lg md:text-2xl lg:text-3xl font-semibold mb-4">
                    {item.Slider_Text}
                  </h2>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          
          
         
          :<Swiper
  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
  spaceBetween={50}
  slidesPerView={1}  // Mobil uyum için tek slide gösterim
  pagination={{ clickable: true }}
  scrollbar={{ draggable: true }}
  autoplay={{ delay: 5000 }} // 5 saniye aralıklarla otomatik geçiş
  navigation
  className="about-us-slider swiper-container p-relative h-full"
>
  {this.state.sliderData.map((item, i) => (
    <SwiperSlide key={i} className="swiper-slide slide-item">
      {/* Slider Görseli */}
      <img
        src={`https://megasubdomain.stechomeyazilim.info/${item.Slider_Photo}`}
        className="img-fluid w-full full-width h-full object-cover"
        alt=""
      />

      {/* Overlay Efekti */}
      <div className="overlay absolute inset-0"></div>

      {/* İçerik */}
      <div className="transform-center absolute inset-0 flex flex-col justify-center items-center text-center">
        <h1 className="text-white font-bold sm:text-2xl md:text-4xl lg:text-6xl mb-4">
          {item.Slider_Text}
        </h1>
      
      </div>
    </SwiperSlide>
  ))}
</Swiper>

        );
    }
}

export default Banner;