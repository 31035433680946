import React, { useEffect, useState } from "react";
import twtlogo from '../assets/tiwtternocolor.svg';
import fcblogo from '../assets/facebooknocolor.svg';
import instalogo from '../assets/instanolcolor.svg';

import ytblogo from '../assets/ytbnocolor.svg';
import tiktoklogo from '../assets/tiktok.svg';
import axios from 'axios';
import { Link } from "react-router-dom";
import footerimg from '../assets/megaLogo.png';

import NavColorFooter from "./NavColorFooter"


import { faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {

 const [newsData, setData] = useState([]);
  const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';
  useEffect(() => {

    axios.get('https://getjob.stechomeyazilim.info:5101/getContactMega/select/')
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, []);


  return (
    <div className="bg-gradient-to-r from-[#006890] to-[#3DB2E0] text-white p-8">
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
      
      {/* Logo ve Kurumsal Bilgiler */}
      <div className="flex items-center w-full md:w-1/3">
        <img src={footerimg} alt="Footer Logo" className="w-30 h-20 mr-4" />
        <div>
          <h3 className="text-xl font-bold  text-center">KURUMSAL</h3>
          <nav className="flex flex-col mt-2 justify-center items-center">
            <Link to="/" className="text-sm mb-1 hover:text-[#3DB2E0] transition-colors">ANASAYFA</Link>
            <Link to="/hakkimizda" className="text-sm mb-1 hover:text-[#3DB2E0] transition-colors">HAKKIMIZDA</Link>
            <Link to="/iletisim" className="text-sm mb-1 hover:text-[#3DB2E0] transition-colors">İLETİŞİM</Link>
            <Link to="/sss" className="text-sm mb-1 hover:text-[#3DB2E0] transition-colors">SSS</Link>
          </nav>
        </div>
      </div>
  
      {/* İletişim Bilgileri */}
      {newsData.length > 0 && (
        <div className="flex flex-col w-full md:w-1/3 justify-center items-center">
          <h3 className="text-xl font-bold  text-center">İLETİŞİM</h3>
          <div className="mt-2">
            <a href="/" className="text-sm mb-1 flex items-center hover:text-[#3DB2E0] transition-colors">
              <FontAwesomeIcon icon={faLocation} className="mr-2" /> {newsData[0].Contact_Adress}
            </a>
            <a href="/" className="text-sm mb-1 flex items-center hover:text-[#3DB2E0] transition-colors">
              <FontAwesomeIcon icon={faPhone} className="mr-2" /> {newsData[0].Contact_TelephoneNumber}
            </a>
            <a href="/" className="text-sm mb-1 flex items-center hover:text-[#3DB2E0] transition-colors">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {newsData[0].Contact_EmailAdress}
            </a>
          </div>
        </div>
      )}
  
      {/* Sosyal Medya Bağlantıları */}
      <div className="flex flex-col w-full md:w-1/3 justify-center items-center">
        <div className="flex justify-center mt-2">
          <a href="#" className="bg-[#8dd9f7] rounded-full p-3 m-1 transition-transform hover:scale-110">
            <img src={twtlogo} alt="Twitter" className="w-5 h-5" />
          </a>
          <a href="#" className="bg-[#3b5998] rounded-full p-3 m-1 transition-transform hover:scale-110">
            <img src={fcblogo} alt="Facebook" className="w-5 h-5" />
          </a>
          <a href="#" className="bg-[#DD2A7B] rounded-full p-3 m-1 transition-transform hover:scale-110">
            <img src={instalogo} alt="Instagram" className="w-5 h-5" />
          </a>
          <a href="#" className="bg-red-500 rounded-full p-3 m-1 transition-transform hover:scale-110">
            <img src={ytblogo} alt="YouTube" className="w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  
    {/* Alt Bilgilendirme */}
    <div className="mt-8 border-t border-white pt-5 text-center">
      <p className="text-sm">©2024 by Stechome Software. Tüm hakları saklıdır.</p>
    </div>
  </div>
  
  );
};

export default Footer;
