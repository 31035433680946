import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Navbar from "../Navbar2";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { Link, useLocation } from 'react-router-dom';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'

import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer"
import utf8 from "utf8"

const Content = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleImageClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';
    const location = useLocation();
    const state = location.state;

    return (
        <div className="flex flex-col justify-center w-full min-h-screen bg-gray-100">
        <Navbar />
      
        <div className="w-full mt-20 flex flex-col items-center bg-[#F7F7F7]">
          <div className="w-full py-12 bg-gradient-to-br from-[#5BC2EB] via-[#006890] to-[#5BC2EB] text-white text-center">
            <h1 className="text-3xl font-bold p-2 flex justify-center items-center">
              <FontAwesomeIcon
                className="text-white transform rotate-[-17deg] mr-2"
                icon={faBullhorn}
              /> 
              {state._item.News_Title}
            </h1>
            <div className="mt-4 flex justify-center">
              <Link className="font-bold text-white py-2 px-4 border-r-2 border-white mr-2" to="/">
                Anasayfa
              </Link>
              <Link className="font-bold text-white py-2 px-4" to="/news">
                Haberler
              </Link>
            </div>
          </div>
        </div>
      
        <div className="flex flex-col items-center w-full p-4">
          <div className="max-w-2xl w-full text-center">
            <img
              src={imgUrl + state._item.News_PhotoLink}
              alt={state._item.News_Title}
              className="w-full object-cover rounded-xl shadow-lg mb-4"
            />
            <p className="text-gray-500 mb-6">{moment(state._item.News_CreteadDateTime).format("Do MMMM YYYY, h:mm")}</p>
          </div>
      
          <p className="font-bold text-[#006890] text-xl text-center">DETAY</p>
          <div className="p-4 max-w-2xl w-full text-center bg-white shadow-lg rounded-lg mt-4">
            <p className="font-medium text-[#585858]">
              <ReactHtml html={utf8.decode(base64.decode(state._item.News_Explanation))} />
            </p>
          </div>
      
          <div className="mt-8 w-full max-w-2xl">
            <p className="font-bold text-[#006890] text-md text-left border-b border-gray-300 mb-4">Haber Görselleri</p>
      
            <div onClick={handleImageClick} style={{ cursor: 'pointer' }} className="flex justify-center">
              <img
                src={imgUrl + state._item.News_PhotoLink}
                alt={state._item.News_Title}
                className="w-36 object-cover rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="relative bg-white p-8 max-w-full max-h-full overflow-auto shadow-lg rounded-lg">
              <img
                src={imgUrl + state._item.News_PhotoLink}
                alt={state._item.News_Title}
                className="rounded-md object-contain w-full h-auto"
              />
              <button className="absolute top-4 right-4 text-black" onClick={handleCloseModal}>
                X
              </button>
            </div>
          </div>
        )}
      
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        
        <Footer />
      </div>
      
    );
}


export default Content;

