import React, { useEffect } from "react";

import { useState } from "react";

import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

import { observer, inject } from 'mobx-react'


import twtlogo from '../assets/tiwtternocolor.svg';
import fcblogo from '../assets/facebooknocolor.svg';
import instalogo from '../assets/instanolcolor.svg';
import linkedinlogo from '../assets/linkedinnocolor.svg';
import ytblogo from '../assets/ytbnocolor.svg';
import tiktoklogo from '../assets/tiktok.svg';
import { faEnvelope, faHome, faInfo, faPhone, faQuestion, faRightFromBracket, faRightToBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = inject()(observer((props) => {

    const [nav, setNav] = useState(true);


    const [product, setProduct] = useState(false);
    const [deliverables, setDeliverables] = useState(false);

    const [checkDisplayMenu, setDisplayMenu] = useState(false);


    const hideDropdownMenu = () => {
        setDisplayMenu(!checkDisplayMenu)
        document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);

    }
    return (

        <div className="w-full fixed  flex h-1 flex-nowrap shadow z-20 bottom-10 ">
            <div className="w-1/5 bg-[#3DB2E0]"></div>
            <div className="w-1/5 bg-[#EBE244]"></div>
            <div className="w-1/5 bg-[#E0536F] "></div>
            <div className="w-1/5 bg-[#3DB2E0]"></div>
            <div className="w-1/5 bg-[#EB738A]"></div>
            <div className="w-1/5 bg-[#EBE244]"></div>
            <div className="w-1/5 bg-[#2E7E9E]"></div>
            <div className="w-1/5 bg-[#E0536F] "></div>
            <div className="w-1/5 bg-[#3DB2E0]"></div>
            <div className="w-1/5 bg-[#EB738A]"></div>
            <div className="w-1/5 bg-[#EBE244]"></div>

        </div>
    );
}));

export default Navbar;

