import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from "../Navbar2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faClose, faGreaterThan, faImage, faLeftLong } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';

const App = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    axios
      .get('https://getjob.stechomeyazilim.info:5101/getGalleryMega/select/')
      .then((res) => {
        setNewsData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Navbar />
    
      <div className="w-full mt-20 flex flex-col items-center bg-[#F7F7F7]">
  <div className="bg-gradient-to-br from-[#EB738A] to-[#FF7A7A] w-full py-12 text-white text-center shadow-2xl rounded-lg">
    <h1 className="text-4xl font-extrabold p-2 flex items-center justify-center drop-shadow-md">
      <FontAwesomeIcon className="text-white transform rotate-[-17deg] mr-3 text-4xl" icon={faImage} />
      MEDYA
    </h1>
    <div className="mt-6 flex justify-center space-x-8">
      <Link  to={"/"} className="font-bold text-white py-3 px-6 rounded-md transition-all duration-300 transform hover:scale-110 hover:bg-white hover:text-[#EB738A] shadow-md">Anasayfa</Link>
    </div>
  </div>
  {newsData.length > 0 && <TabBar newsData={newsData} />}


      </div>
      <Footer />
    </div>
  );
};

const TabBar = ({ newsData }) => {
  const [activeTab, setActiveTab] = useState(newsData.length > 0 ? newsData[0].ID : null);

  useEffect(() => {
    if (newsData.length > 0) {
      setActiveTab(newsData[0].ID);
    }
  }, [newsData]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    setCurrentIndex(0); // Reset the currentIndex when changing tabs
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageVisible, setSelectedImageVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTabImages, setCurrentTabImages] = useState([]);

  useEffect(() => {
    const currentIndex = newsData.findIndex((item) => item.ID === activeTab);
    setCurrentTabImages(newsData[currentIndex]?.Gallery_Multiple || []);
  }, [activeTab, newsData]);

  const handleNavigation = (direction) => {
    const maxIndex = currentTabImages.length - 1;

    if (direction === 'next') {
      setCurrentIndex((prevIndex) => (prevIndex === maxIndex ? 0 : prevIndex + 1));
    } else if (direction === 'previous') {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? maxIndex : prevIndex - 1));
    }
  };

  const openModal = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setSelectedImageVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageVisible(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container px-4 py-8">
          <div className="flex flex-col w-full justify-center bg-[#eeeeee] items-center mt-5">
            <Modal size="md" isOpen={selectedImageVisible} backdrop={true} toggle={() => setSelectedImageVisible(false)} centered={true}>
              <ModalBody className="relative px-4 py-5 text-center w-full" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                {selectedImage != null ? (
                  <div className="relative justify-center flex">
                    <img
                      src={imgUrl + currentTabImages[currentIndex]?.Gallery_Multiple_Link}
                      alt=""
                      className="w-2/3 relative"
                    />
                    <button
                      className="absolute top-56 left-0  text-gray px-4 py-2"
                      onClick={() => handleNavigation('previous')}
                    >
                      <FontAwesomeIcon className="text-[white]" size="2x"  icon={faChevronLeft} />
                    </button>
                    <button
                      className="absolute top-56 right-0  text-gray px-4 py-2"
                      onClick={() => handleNavigation('next')}
                    >
                    <FontAwesomeIcon className="text-[white]" size="2x"  icon={faChevronRight} />
                    </button>
                    <button
                      className="absolute top-0 right-0 text-white px-2  "
                      onClick={() => setSelectedImageVisible(false)}
                    >
                                 <FontAwesomeIcon className="text-gray" size="x"  icon={faClose} />
                    </button>
                  </div>
                ) : null}
              </ModalBody>
            </Modal>

            <div className="grid grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xxs:grid-cols-2 gap-3 w-full">
              {newsData.map((item) => (
                <button
                  key={item.ID}
                  className={`font-[Lato-Medium] py-2 px-4 shadow-md  flex justify-center items-center flex-col text-[gray] ${activeTab === item.ID ? 'border-[#EB738A] border-b-4  bg-white border-opacity-75 ' : 'bg-white'
                    }`}
                  onClick={() => handleTabClick(item.ID)}
                >
                  {item.Gallery_Title}
                </button>
              ))}
            </div>

            <div className="border-t-0 p-4">
              {currentTabImages.length > 0 ? (
                <div className="mt-4 flex-row flex grid grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xxs:grid-cols-1 gap-4 w-full">
                  {currentTabImages.map((galleryItem, index) => (
                    <img
                      key={galleryItem.ID}
                      src={imgUrl + galleryItem.Gallery_Multiple_Link}
                      alt=""
                      className="w-56 h-56 object-cover rounded-md cursor-pointer"
                      onClick={() => openModal(galleryItem, index)}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default App;
