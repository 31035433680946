// src/App.js

import React from 'react';
import Logo from "../../assets/lo.png";
import Logo2 from "../../assets/log.png";
import Logo3 from "../../assets/you.png";
import diksiyon from "../../assets/diksiyon.png";
import müzik from "../../assets/müzik.png";
import maker from "../../assets/maker.png";
import dil from "../../assets/dil.png";
import kariyer from "../../assets/kariyer.png";
import bilim from "../../assets/bilim.png";
import mimari from "../../assets/mimari.png";
import astronomi from "../../assets/astronomi.png";
import cebir from "../../assets/cebir.png";

import { Link, useLocation } from 'react-router-dom';
function App() {
    return (
        <div className="App">
            <header className="App-header">

                <img
                    src={Logo2} // Örnek bir resim URL'si
                    alt="Resim"
                    className="w-44 h-44 object-contain mb-6 rounded-md mx-auto" // mx-auto: yatayda ortalar
                />

                <div className="relative">
                    <img
                        src={Logo}
                        alt="Resim"
                        className="w-full mb-6 rounded-md"
                    />
                    <Link to='https://panel.meramgelisim.com/Login'
                        className="bg-[#ffd900] text-black py-2 px-4 rounded-md absolute bottom-40 right-52"
                    >
                        BAŞVUR
                    </Link>
                </div>

<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>


                    <img src={diksiyon} className="rounded-md" />
                    <img src={müzik} className="rounded-md" />
                    <img src={maker} className="rounded-md" />
                    <img src={dil} className="rounded-md" />



                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>



                    <img src={kariyer} className="rounded-md" />
                    <img src={bilim} className="rounded-md" />
                    <img src={mimari} className="rounded-md" />
                    <img src={astronomi} className="rounded-md" />
                    <img src={cebir} className="rounded-md" />



                </div>
                </div>
                <div className='m-12' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p className="text-gray-600" style={{ width: '70%', marginBottom: 20 }}> Dijital medyanın hayatımızdaki öneminin artmasına paralel video içeriklerinin de önemi her geçen gün artmaktadır. Günümüzde İnternet trafiğinin yüzde 80’ini videolar oluştururken Youtube Google’dan sonra en sık kullanılan 2. arama motoru olmuş durumdadır. Ayrıca klasik televizyon yayınları yerini bağımsız Youtube kanallarına devretmeye başlamıştır.

                        Günümüzde birçok insan Youtube ya da benzeri sosyal medya platformlarına video içerik üretmek için bir yarış halindedir. Her ay 1,9 milyar kişi Youtube'u ziyaret ederken Youtube’un günlük aktif ziyaretçi sayısı 30 milyondur. Youtube'da bir günde izlenen video sayısı ise 5 milyardır.

                        Popüler sosyal medya uygulaması Instagram’ın videoları desteklemesi, IGTV’yi ve Reels’i devreye sokması, ürün ve hizmet tanıtımında video kullanımının artması, videonun gelecekte hayatımızın daha da önemli bir parçası olacağını göstermektedir.

                        Ayrıca insanlar 30 gün içinde izlediği videoların yüzde 80'ini hatırlayabilmektedir. Dijital dünyadaki tüm bu gelişmeler ile video her geçen günü önemini arttırmaktadır. Bu kapsamda Meram Gelişim Akademisi bünyesinde açılacak olan Youtube Akademi ile ile MEGA eğitim felsefesine uygun olarak çocukların ve gençlerin bu dünyanın bir parçası olması, başarılı ve etkileyici videolar ile faydalı içerikler üretmesi hedeflenmektedir.




                    </p>
                    <img
                        src={Logo3} // Örnek bir resim URL'si
                        alt="Resim"
                        style={{ width: '30%' }}
                    />
                </div>
  
            </header >
        </div >
    );
}

export default App;
