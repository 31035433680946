import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { faEnvelope, faHome, faInfo, faPhone, faQuestion, faRightFromBracket, faRightToBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = inject()(observer((props) => {
  const [newsData, setData] = useState([]);
  const imgUrl = 'https://megaapi.stechomeyazilim.info';

  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getContactMega/select/')
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const [checkDisplayMenu, setDisplayMenu] = useState(false);

  const hideDropdownMenu = () => {
    setDisplayMenu(!checkDisplayMenu);
    document.querySelector('#dropdown').removeEventListener('click', hideDropdownMenu);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobil cihaz olarak kabul ettiğimiz ekran genişliği
    };

    handleResize(); // Başlangıçta kontrol etmek için çağırıyoruz

    window.addEventListener('resize', handleResize); // Ekran boyutu değiştiğinde kontrol etmek için event listener ekliyoruz

    return () => {
      window.removeEventListener('resize', handleResize); // Component kaldırıldığında event listener'ı kaldırıyoruz
    };
  }, []);

  return (
    <nav className="w-full fixed bg-[#006890] shadow z-20">
      
   { isMobile ?  
      
      newsData.length > 0 &&


        <div className="container h-12 flex justify-center flex-col">
          <div className="flex items-center">
            <div className="p-2 mr-4 rounded-full bg-[#EBE244] w-6 h-3 flex items-center justify-center shadow-l">
              <FontAwesomeIcon icon={faPhone} color={'white'} size="xs" />
            </div>
            <Link className="text-white" to={`tel:${newsData[0].Contact_TelephoneNumber}`}>{newsData[0].Contact_TelephoneNumber}</Link>
          </div>
          <div className="flex items-center ">
            <div className="p-2 mr-4 rounded-full bg-[#5BC2EB] w-6 h-3 flex items-center justify-center shadow-l">
              <FontAwesomeIcon icon={faEnvelope} color={'white'} size="xs" />
            </div>
            <Link className=" text-white navbar-link" to={`mailto:${newsData[0].Contact_EmailAdress}`}>{newsData[0].Contact_EmailAdress}</Link>
          
          </div>
        </div>
       
   
      
:
newsData.length > 0 &&


    <div className="container h-12 flex justify-end">
      <div className="flex items-center m-3">
        <div className="mr-2 rounded-full bg-[#EBE244] w-6 h-6 flex items-center justify-center shadow-l">
          <FontAwesomeIcon icon={faPhone} color={'white'} size="xs" />
        </div>
        <Link className="text-white" to={`tel:${newsData[0].Contact_TelephoneNumber}`}>{newsData[0].Contact_TelephoneNumber}</Link>
      </div>
      <div className="flex items-center m-3">
        <div className="mr-2 rounded-full bg-[#5BC2EB] w-6 h-6 flex items-center justify-center shadow-l">
          <FontAwesomeIcon icon={faEnvelope} color={'white'} size="xs" />
        </div>
        <Link className=" text-white navbar-link" to={`mailto:${newsData[0].Contact_EmailAdress}`}>{newsData[0].Contact_EmailAdress}</Link>
      
      </div>
    </div>
   

  

    }  
    </nav>
  );
}));

export default Navbar;
