import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import "./index.css";
import App from "./App";

import { Provider } from 'mobx-react';
import { HashRouter } from "react-router-dom";
import twtlogo from './assets/tiwtternocolor.svg';
import fcblogo from './assets/facebooknocolor.svg';
import instalogo from './assets/instanolcolor.svg';
import { Link } from "react-router-dom";
import ytblogo from './assets/ytbnocolor.svg';
const root = ReactDOM.createRoot(document.getElementById("root"));

const MyComponent = () => {
	const [newsData, setData] = useState([]);
	const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';

	useEffect(() => {
		axios.get('https://getjob.stechomeyazilim.info:5101/getContactMega/select/')
			.then(res => {
				setData(res.data);
				console.log("reddd", res.data[0]);
				console.log(res.data[0].Contact_TelephoneNumberSeen, "dd");
			})

			.catch(err => console.log(err));
	}, []);

	return (
		<React.StrictMode>
			<HashRouter>
				<Provider>
					<App />



				</Provider>
			</HashRouter>
		</React.StrictMode>
	);
};

root.render(<MyComponent />);
