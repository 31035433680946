import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "../Navbar2";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64';
import { faCertificate, faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer";
import utf8 from "utf8"

const Content = () => {
    const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';
    const location = useLocation();
    const state = location.state;

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Navbar />

            <div className="relative mt-20 flex flex-col items-center justify-center w-full">
                <img src={imgUrl + state._item.Project_TopBannerImage} alt={state._item.Project_Title} className="w-full h-full object-fill" />
                <div className="absolute bg-[#943a4aaf] xxs:w-full lg:w-full h-full flex items-center justify-center flex-col">
                    <div className="container mx-auto flex items-center justify-center flex-col">
                        <h1 className="text-4xl text-white font-[Lato-Bold]">{state._item.Project_Title}</h1>
                        <div className="mt-4">
                            <Link  to={"/"} className="font-[Lato-Bold] text-white py-2 px-4 border-r-2 border-white mr-2">Anasayfa</Link>
                            <a className="text-white py-2 px-4">Projeler</a>
                        </div>
                    </div>
                </div>
            </div>


        


            <div className=" mx-auto px-4 py-4 justify-center items-center w-full">
                 <div className="justify-center items-center w-full flex">
                <img className='justify-center items-center' src={imgUrl + state._item.Project_Picture} alt={state._item.Project_Picture} />
                </div>
                <div className="prose bg-[#f8f8f8] mt-5 p-5 lg:w-full md:full xxs:w-full m-12 justify-center flex">
                    <p className='font-[Lato-Medium]'>
                        <ReactHtml className="font-[Lato-Medium]  " html={utf8.decode(base64.decode(state._item.Project_Desc))} />
                    </p>
                </div>






                {/*
                       {state._item.Project_SubItems.length > 0 ?
                  <button className='bg-[#943a4a] rounded-lg p-2 text-white' onClick={openModal}>Detayı gör</button>
                 : null}
                 */}


            </div>

            {isOpen && (
                <div className="fixed z-50 top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50">
                    <div className="bg-white  rounded-lg lg:w-1/2 xxs:w-full">
                        <div className="flex justify-end">
                            <button
                                className="bg-[red] rounded-l rounded-r text-[white] px-4 py-2"
                                onClick={<button className="bg-[#943a4a] rounded-lg p-2  text-white" onClick={closeModal}></button>
                                }
                            >
                                x
                            </button>
                        </div>

                        {state._item.Project_SubItems.map((item, index) => (
                            <div className=' justify-center items-center flex flex-col'>
                                <p className='font-[Lato-Bold] text-lg text-[#943a4a]' key={index}>{item.Project_SubItems_Title}</p>
                                <ReactHtml className="font-[Lato-Medium]  " html={utf8.decode(base64.decode(state._item.Project_Desc))} />


                                <hr className="border-[#c9c9c9] w-full" />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="relative mt-16 flex flex-col items-center justify-center mb-5">

                <img src={imgUrl + state._item.Project_TopBannerImage} className="w-full h-full object-cover" />
                <div className="absolute top-0 left-0 bottom-0 w-full h-full flex items-center justify-center">

                    <h1 className="text-xl md:text-xl xxs:text-sm text-white font-[Lato-Bold] m-20 text-center relative z-10 mt-44 md:mt-36 xxs:mt-36">
                        {state._item.Bottom_BannerText}
                    </h1>
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
                </div>
            </div>


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />
        </div>
    );
}

export default Content;
