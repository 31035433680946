import React, { useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaKey } from "react-icons/fa";
import { AiFillEyeInvisible } from "react-icons/ai";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "react-phone-number-input/style.css";
import "./phoneInput.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  boxShadow: 24,
};

const Autharizatiom = (props) => {
  const [getUserName, setUserName] = useState();

  const [mailAdress, setMail] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState();

  const setChangeConfirm = (value, type) => {
    setPasswordConfirm(value)
  }


  const _signUpHandle = async () => {

    try {
      const registerDataNew = {
        Users_NameSurname: getUserName,
        Users_Photo: '/Uploads/user.png',
        Users_Mail: mailAdress,
        Users_Password: passwordConfirm,
        Users_Type_ID: 1
      }

      await axios.post(`https://getjob.stechomeyazilim.info:5101/userPostEP/send`, registerDataNew).then(async (res) => {

        localStorage.setItem('dataLogin', JSON.stringify(registerDataNew));
        showToast('Epilepsi Eğitim', "Başarıyla Kayıt Oldunuz!", 'success')

      })

    } catch (err) {
      console.log("_signUpHandle", err)
    }


  }


  const setChange = (value, type) => {
    if (type == "mail") {
      setMail(value)
    } if (type == "password") {
      setPassword(value)
    }
    if (type == "userName") {
      setUserName(value)
    }

  }

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);

  const [getDataSignUp, saveDataSignUp] = useState(false);



  const [forgotPassword, setForgotPassword] = useState(false);
  const [check, setCheck] = useState(false);

  const [openSignUp, setOpenAuth] = useState(false);


  const handleAuth = () => {
    setOpenAuth(!openSignUp);
  };


  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("dataLoginEpilepsi"))
    if (data != null) {
      setCheck(true)
      setPassword(data.password)
      setEmail(data.mail)
    } else {
      setCheck(false)
    }


  }, []);


  const showToast = (event, value, type) => {
    if (type == "false") {
      toast.error(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } else {
      toast.success(value, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      window.location.reload(false);

    }

  }

  const saveData = async () => {
    try {

      let loginData = {
        mail: email,
        password: password
      }
      setCheck(!check)
      localStorage.setItem('dataLoginEpilepsi', JSON.stringify(loginData));

    } catch (error) {
      console.log("getList" + error)
    }

  }
  const getList = async () => {
    try {
      await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserLoginEpilepsi/select/${mailAdress}/${password}`)
        .then(async (res) => {
          if (res.data.length == 0) {

            showToast('Epilepsi Eğitim', "Giriş Bilgileriniz Yanlış!", 'false')
          } else {

            localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));


            showToast('Epilepsi Eğitim', "Başarıyla Giriş Yaptınız!", 'success')

            if (check == true) {
              let loginData = {
                mail: email,
                password: password
              }
              localStorage.setItem('dataLoginEpilepsi', JSON.stringify(loginData));
            }

          }
        })

    }
    catch (error) {
      console.log("getList" + error)
    }
  }


  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  return (
    <Modal
      open={props.openAuthModalVisible}
      onClose={props._closeAuthModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className='' sx={style}>

        {openSignUp == true ? <div className=" flex justify-center items-center   z-40">
          <div className={"flex flex-col  justify-center items-center bg-white shadow-2xl    rounded-lg w-[470px] px-2 pt-2`"}>

            <div>
              <div className="flex justify-end z-50 mr-[-40px] ">
                <button
                  onClick={props.handleSignUp}
                  className="bg-[red] rounded-full p-2"
                >
                  <GrClose className="" />
                </button>
              </div>

              <div className="flex w-full  shadow-xl  mb-4 mt-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   " >
                  <BsFillPersonFill className="ml-3 mr-7 flex justify-center items-center w-7 h-7 " />
                  <input
                    type="email"
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                    placeholder="E-posta Adresiniz"
                    value={mailAdress}
                    onChange={e => setChange(e.target.value, "mail")}
                  />
                </div>
              </div>

              <div className="flex w-full  shadow-xl  mb-4 mt-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   " >
                  <BsFillPersonFill className="ml-3 mr-7 flex justify-center items-center w-7 h-7 " />
                  <input
                    type="Kullanıcı Adınız"
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                    placeholder="Kullanıcı Adınız"
                    value={getUserName}
                    onChange={e => setChange(e.target.value, "userName")}
                  />
                </div>
              </div>

              <div className="flex w-full  shadow-xl  mb-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "

                >
                  <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#081F43]" />
                  <input
                    type={passwordShown ? "text" : "password"}

                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                    placeholder="Şifreniz"
                    onChange={e => setChange(e.target.value, "password")}
                  />
                  <div className=" px-3 flex ">
                    <button onClick={() => setPasswordShown(!passwordShown)} className="rounded-full font-[Gotham-Medium] hover:bg-white mr-2 justify-center items-center p-1 ">
                      <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex w-full  shadow-xl  mb-4">
                <div
                  className="w-full flex py-3 bg-zinc-200  justify-center items-center   "

                >
                  <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-[#081F43] " />
                  <input
                    type={passwordShownConfirm ? "text" : "password"}
                    onChange={e => setChangeConfirm(e.target.value, "password")}
                    className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-[#081F43] "
                    placeholder="Tekrar Şifre"
                  />
                  <div className=" px-3 flex ">
                    <button onClick={() => setPasswordShownConfirm(!passwordShownConfirm)} className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                      <AiFillEyeInvisible className="w-6 h-6 text-[#081F43]" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full flex mb-4">
                <button
                  onClick={_signUpHandle}
                  className="mx-auto border bg-[#2E7E9E] hover:[#081F43] hover:bg-[#006890] text-[white] px-32 py-4 rounded-lg shadow-md "
                >
                  <h2 className="font-[Gotham-Medium]">Kaydı Tamamla</h2>
                </button>
              </div>
              <div className="flex mt-2 text-center justify-center items-center">

                <FormGroup>
                  <FormControlLabel onClick={() => saveDataSignUp(!getDataSignUp)} control={<Checkbox defaultChecked={getDataSignUp} checked={getDataSignUp} />} label={<a href="https://getjobweb.stechomeyazilim.com/getjob.pdf" target={"_blank"} className="font-[Times] text-center text-sm">{`Kullanım ve gizlilik koşullarını ${getDataSignUp == true ? "KABUL EDİYORUM" : "KABUL ETMİYORUM"}`}</a>} />
                </FormGroup>


              </div>
              <div className="w-full bg-[#006890] flex py-4 mb-2   rounded-lg">
                <div className="flex mx-auto justify-center items-center">
                  <h3 className="mr-4 color font-[Gotham-Medium] text-white">Zaten Bir Hesabınız Var Mı?</h3>
                  <button className="font-[Gotham-Medium] p-3 border bg-[white] hover:text-white hover:bg-[#2E7E9E] rounded-lg " onClick={handleAuth}>
                    <p className=" font-[Gotham-Medium] hover:text-white   text-[#006890]">Giriş Yap</p>
                  </button>
                </div>
              </div>
            </div>


          </div>

        </div> :

          <div className="w-full ">

            <div
              className={`${forgotPassword
                  ? "hidden"
                  : "flex flex-col  justify-center items-center"
                } bg-[#2E7E9E] shadow-2xl    rounded-lg w-[470px] px-2  `}
            >

              <div className="flex justify-end z-50 absolute -right-2 -top-5">
                <button
                  className="bg-[white] rounded-full p-2"
                  onClick={props.handleLogIn}
                >
                  <GrClose className="" />
                </button>
              </div>

              {!forgotPassword && (
                <div className="w-full justify-center items-center">

                  <div className="w-full flex p-2 rounded-b-md bg-white justify-center items-center">
                    <h2 className="text-center font-[Gotham-Medium] text-[gray] ">Giriş Yap</h2>
                  </div>
                  <div className="flex w-1/2 justify-center items-center shadow-xl  mx-auto  m-2 ">
                    <div
                      className=" w-full flex py-3 bg-zinc-200 font-[Gotham-Medium] justify-center items-center  rounded-md "
                    >
                      <BsFillPersonFill className="ml-3 mr-7 flex justify-center items-center w-7 h-7 " />
                      <input
                        type="email"

                        className="bg-zinc-200 w-full font-[Gotham-Medium] outline-none focus:border-b-2 focus:border-blue-500 mr-3"
                        placeholder="E-posta Adresiniz"
                        value={mailAdress}
                        onChange={e => setChange(e.target.value, "mail")}
                      />
                    </div>
                  </div>
                  <div className="flex w-full  shadow-xl   mb-1">
                    <div
                      className="w-1/2 flex py-3 rounded-md bg-zinc-200 mx-auto  m-2 justify-center items-center   "
                    >
                      <FaKey className="ml-3 mr-7 flex justify-center items-center w-6 h-6 fill-green-600" />

                      <input
                        value={password}
                        type={passwordShown ? "text" : "password"}

                        className="bg-zinc-200 w-full font-[Gotham-Medium]  outline-none focus:border-b-2 focus:border-blue-500 "
                        placeholder="Şifreniz"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="px-1 flex ">
                        <button onClick={() => setPasswordShown(!passwordShown)} className="rounded-full hover:bg-white mr-2 justify-center items-center p-1 ">
                          <AiFillEyeInvisible className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full flex mb-3">
                    <FormGroup>
                      <FormControlLabel onClick={() => saveData()} control={<Checkbox checked={check} />} label={<text className="text-white text-[Gotham-Medium]">Beni Hatırla</text>} />
                    </FormGroup>

                    <button onClick={() => getList()} className="w-full flex">
                      <div className=" mx-auto border  p-2 bg-white hover:text-white px-32 py-4 rounded-lg shadow-md">
                        <h2 className=" font-[Lato-Medium] text-[gray]">Giriş Yap</h2>
                      </div>
                    </button>



                  </div>


                  <div className="w-full bg-[white] flex py-4 mb-2   rounded-lg">
                    <div className="flex mx-auto justify-center items-center">
                      <h3 className="mr-4 text-[#2E7E9E] font-[[#006890]]">Hesabınız Yok Mu?</h3>
                      <button className=" p-3 border bg-[white] hover:text-white hover:bg-[#006890] rounded-lg " onClick={handleAuth}>
                        <p className="font-[Lato-Bold] hover:text-white text-[#006890]">Kayıt Ol</p>
                      </button>
                    </div>
                  </div>

                </div>
              )}
            </div>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

          </div>}
      </Box>
    </Modal>
  );
};

export default Autharizatiom;
