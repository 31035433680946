import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar2";
import { Link } from 'react-router-dom';
import Footer from "../Footer";
import { Breadcrumbs } from "@material-tailwind/react";
import axios from 'axios';
import { MapPinIcon, PhoneIcon, EnvelopeIcon, SignalIcon } from "@heroicons/react/24/solid";
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'

const Hakkimizda = () => {
    const [getAbout, setAbout] = useState(null);
    const [getAboutHtml, setAboutHtml] = useState(null);

    useEffect(() => {

        axios.get('https://getjob.stechomeyazilim.info:5101/getAboutLG/select/')
            .then(res => {
                setAbout(res.data.value[0])
                setAboutHtml( base64.decode(base64.decode(res.data.value[0].Company_About)))
            })
            .catch(err =>   (err));
    }, []);


    return (
        <div >
            <Navbar />
           { getAbout != null ? <div className=" flex flex-col justify-center ">

                <div className="  max-w-full mt-36  flex flex-col h-full p-10  bg-gradient-to-r from-[#006890] via-[#D08E38] to-[#D08E38] items-center   ">
                    <h3 className=" text-4xl w-full text-center  py-3 ">
                        <span class="text-transparent bg-clip-text bg-white font-[Lato-Medium]">Hakkımızda
                        </span>
                    </h3>
                    <div className='flex justify-center'>

                        <Breadcrumbs className='bg-white'>
                            <Link to="/" className="opacity-90 text-black font-[Lato-Medium]">
                                Anasayfa
                            </Link>

                            <Link href="/hakkimizda" className="text-black font-[Lato-Medium]" >Hakkımızda</Link>
                        </Breadcrumbs>
                    </div>
                </div>




                <div className="max-w-full    h-full  items-center  flex">

                    <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 xxs:w-4/5  items-center  flex flex-col">
                        <div className="flex flex-wrap justify-center items-center sm:px-24 p-10">
                            <h2 className="text-4xl font-bold text-center font-[Lato-Medium]">
                                {getAbout.Company_Name}
                            </h2>
                        </div>
                       
                        <div className=" h-full lg:4/5 md:w-2/5 sm:w-full xxs:w-full p-5 mb-5 items-center rounded-xl  bg-gradient-to-r from-[#006890] to-[#D08E38] ">
                        <div className="flex flex-wrap flex-row pb-4  ">
                            <div className="flex flex-wrap justify-center  w-full flex-row">
                                <EnvelopeIcon strokeWidth={1} className="w-4 text-white  " />
                                <h2 className="text-[16px]  ml-3 text-white font-[Lato-Medium]  font-extrabold text-start">

                                    {getAbout.Company_MailAdress}
                                </h2>

                            </div>

                        </div>


                        <div className="flex flex-wrap justify-start  w-full flex-row pb-4">
                            <div className="flex flex-wrap justify-center  w-full flex-row">
                                <SignalIcon strokeWidth={1} className="w-4 text-white  " />
                                <h2 className="text-16px] ml-3 text-white font-[Lato-Medium]  text-start">

                                    {getAbout.Company_WebAdress}
                                </h2>

                            </div>

                        </div>

                        <div className="flex flex-wrap justify-start  w-full flex-row ">
                            <div className="flex flex-wrap justify-center  w-full flex-row">
                                <MapPinIcon strokeWidth={1} className="w-4 text-white  " />
                                <h2 className="text-[16px] ml-3 text-white font-[Lato-Medium]  font-extrabold text-start">

                                    {getAbout.Company_Adress}
                                </h2>

                            </div>

                        </div>

                    
                    </div>


                    </div>

                    <div className="w-5/6 h-full lg:w-3/5 md:w-4/5 xxs:w-4/5  items-center  flex flex-col">
                     
                    <div className="flex flex-wrap justify-center items-center sm:px-24 p-10">
                            <h2 className="text-4xl font-bold text-center font-[Lato-Medium]">
                                {getAbout.Company_Name} Hakkında
                            </h2>
                        </div>
                     
                       
                        <div className=" h-full lg:4/5 md:w-2/5 sm:w-full xxs:w-full p-5 mb-5 items-center rounded-xl  bg-gradient-to-r from-[#006890] to-[#D08E38] ">
                      
                        <div className="flex flex-wrap justify-start  w-full flex-row ">
                            <div className="flex flex-wrap justify-center  w-full flex-row">
                                <ReactHtml style={{color:'white'}} html={getAboutHtml} />
                            </div>

                        </div>
                    
                    </div>


                    </div>

                </div>


              
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d398.92628042750533!2d30.72631051258151!3d36.88053297993222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDUyJzQ5LjkiTiAzMMKwNDMnMzUuNyJF!5e0!3m2!1str!2str!4v1680609485477!5m2!1str!2str" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                <Footer />

            </div> : null }


        </div>
    )
}




export default Hakkimizda;


