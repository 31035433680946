import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import Navbar from "../Navbar2";
import { ToastContainer, toast } from 'react-toastify';

import { Link, useLocation } from 'react-router-dom';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import utf8 from "utf8";
import Footer from "../Footer";

const Content = ({ isOpen, onClose }) => {
    const [newsData, setData] = useState([]);
    const [currentImage, setCurrentImage] = useState(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    useEffect(() => {
        axios.get('https://getjob.stechomeyazilim.info:5101/getPopupMega/select/')
            .then(res => {
                setData(res.data);
            })
            .catch(err => console.log(err));
    }, []);

    const handleImageClick = (image) => {
        setCurrentImage(image);
    };

    const handleOutsideClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            onClose();
        }
    };

    const popupRef = useRef(null);
    const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    const handlePopupClose = () => {
        setCurrentImage(null);
    };

    return (
        <div  >
            <Navbar />
         


            <div className="relative mt-20 flex flex-col items-center justify-center">
                <img src={imgUrl + state._item.Mega_BannerImage} alt={state._item.Mega_Title} className="w-screen h-96 object-fill" />
                
            </div>

            <div className="flex w-screen min-h-[300px]  p-24  bg-white rounded-lg justify-center  items-center flex-col">

                <p className="text-base text-gray-500 text-md font-[Lato-Medium]">
                    <ReactHtml style={{ color: 'white' }} html={utf8.decode(base64.decode(state._item.Mega_Desc))} />
                </p>
            </div>



         {/*   <div className=' bg-gradient-to-b from-[#EB738A] flex flex-col p-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center ' }}>

                <p className=' mt-5 font-[Lato-Bold] text-center text-3xl underline  text-[white]'>MEGA GALERİ</p>
                <div className="flex w-full   rounded-lg justify-center items-center flex-col  p-5">

                    <Slider className="w-5/6 p-5 " {...settings}>
                        {newsData != null
                            ? newsData.map((image, index) => (

                                <div className='flex flex-col items-center '>
                                    <div className='flex flex-col  mr-5 items-center'>
                                        <img
                                            key={index}
                                            src={imgUrl + image.Popup_PhotoLink}
                                            className='object-contain h-48 w-48 mx-2 cursor-pointer hover:opacity-75'
                                            onClick={() => handleImageClick(imgUrl + image.Popup_PhotoLink)}
                                        />
                                        <p className='text-[#006890] font-[Lato-Bold]'>{image.Popup_Name}</p>
                                    </div>
                                </div>

                            ))
                            : null}
                    </Slider>
                </div>
            </div>*/}

            {currentImage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"> <div className="flex justify-end">

                </div>

                    <div className="max-w-md mx-auto bg-white p-12 rounded-lg relative">
                        <button
                            className="bg-[red]  text-[white] px-4 py-2 rounded-tr-md absolute top-0 right-0"
                            onClick={handlePopupClose}
                        >
                            x
                        </button>
                        <img src={currentImage} alt="" className="w-full h-auto" />
                    </div>

                </div>
            )}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />
        </div>
    );
}

export default Content;
