
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar2";
import viewImg1 from "../../assets/Blog/viewImage.svg";

import { faBullhorn, faHome, faInfo, faPhone, faQuestion, faRightFromBracket, faRightToBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer";
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from "moment";
import { FaMedium } from "react-icons/fa";

const Blog = () => {
    const [newsData, setData] = useState([]);
    const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';


    useEffect(() => {

        axios.get('https://getjob.stechomeyazilim.info:5101/getNewsMega/select/')
            .then(res => {
                console.log("lşmsdlşfi",res)
                setData(res.data);
            })
            .catch(err => console.log(err));
    }, []);


    const [visibleData, setVisibleData] = useState(7);

    const loadMore = () => {
        setVisibleData(prevVisibleData => prevVisibleData + 7);
    };


    return (
        <div>
            <Navbar />
            <div className="w-full">
                <div className="w-full mt-20 justify-center items-center   bg-[#F7F7F7] h-full flex flex-col">

  <div className="bg-gradient-to-br from-[#006890] to-[#006890] w-full py-12 text-white text-center shadow-2xl rounded-lg">
    <h1 className="text-4xl font-extrabold p-2 flex items-center justify-center drop-shadow-md">
      <FontAwesomeIcon className="text-white transform rotate-[-17deg] mr-3 text-4xl" icon={FaMedium} />
      MEDYA
    </h1>
    <div className="mt-6 flex justify-center space-x-8">
      <Link  to={"/"} className="font-bold text-white py-3 px-6 rounded-md transition-all duration-300 transform hover:scale-110 hover:bg-white hover:text-[#EB738A] shadow-md">Anasayfa</Link>
    </div>
  </div>


           


                    <div className="container mx-auto px-4 py-8">
                      {/**   <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
                            {newsData.slice(0, 1).map((item, index) => (
                                <Link to={"/newsdetail/" + item.ID} state={{ _item: item }} key={index} className="bg-white rounded-lg overflow-hidden shadow-md mb-10">
                                    <img src={imgUrl + item.News_PhotoLink} alt={item.title} className="w-full h-72 object-contain" />
                                    <p className="text-gray-600 px-5 text-center py-2 font-[Lato-Medium] text-sm  ">{moment(item.News_CreteadDateTime).format("Do MMMM  YYYY, h:mm")}   </p>
                                        
                                    <div className=" flex bg-[white] w-full text-[#006890] py-2 px-4 justify-center  transition-colors duration-300 font-[Lato-Medium]"
                                    >
                                        <h2 className="text-[#006890]  text-xl font-[Lato-Bold] mb-2 ">{item.News_Title}</h2>

                                    </div>
                                    <div className=" flex flex-col  justify-center">


                                        <p className="text-gray-600 text-center text-m  p-5 font-[Lato-Medium]">{item.News_ShortExplanation}</p>
                                        <hr className=" border-[#80808021]" />
          
                                                                        </div>
                                    <div className="flex  justify-center hover:bg-[#006890] ">
                                        <Link to={"/newsdetail/" + item.ID} state={{ _item: item }}
                                            className="text-center  bg-white  w-full text-[#006890] hover:bg-[#006890]  hover:text-white  py-2 px-4 rounded  transition-colors duration-300 font-[Lato-Medium]"

                                        >
                                            Haber detayı
                                        </Link>
                                    </div>
                                </Link>
                            ))}
                        </div>*/}
                        <div  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
                            {newsData.map((item, index) => (
                               <Link to={"/newsdetail/" + item.ID} state={{ _item: item }} key={index} className="bg-slate-200 rounded-lg overflow-hidden shadow-md flex flex-col justify-between">

                                    <div className=" flex  flex-col  bg-[white] w-full text-[#006890] justify-center   transition-colors duration-300 font-[Lato-Medium]"
                                    >

                                        <img src={imgUrl + item.News_PhotoLink} alt={item.title} className="w-full h-48 object-cover" />

                                        <p className="text-gray-600 font-[Lato-Medium] text-right text-sm px-4 py-2 ">{moment(item.News_CreteadDateTime).format("Do MMMM  YYYY, h:mm")}   </p>
                                        
                                        <h2 className="text-[#006890] text-center text-xl h-20 font-[Lato-Bold] px-4 flex items-center justify-center">
  {item.News_Title}
</h2>
                                    </div>

                                 {/**    <div className="flex flex-col justify-center bg-white">

                                        <p className="text-gray-600 text-center text-m px-4 mb-4 font-[Lato-Medium]">{item.News_ShortExplanation}</p>
                                     
                                       
                                        <hr className=" border-[#80808021]" />
                                        <Link to={"/newsdetail/" + item.ID} state={{ _item: item }}
                                            className="text-center bg-white  w-full text-[#006890] py-2 px-4 rounded hover:bg-[#006890]  hover:text-white transition-colors duration-300 font-[Lato-Medium]"

                                        >
                                            Haber detayı
                                        </Link>
                                    </div>*/}

                                </Link>
                            ))}
                        </div>
                        {visibleData < newsData.length ? (
                            <div className="flex justify-center mt-4">
                                <button className="bg-gradient-to-b  from-[#006890] via-[#2E7E9E]  to-[#006890] font-[Lato-Medium] text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300" onClick={loadMore}>
                                    Daha Fazla Yükle                                </button>
                            </div>
                        ) : null}
                    </div>



                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Blog;
