// src/App.js

import React from 'react';
import Logo from "../../assets/oyun.png";
import Logo2 from "../../assets/logooyun.png";
import Logo3 from "../../assets/oyun1.png";
import { Link, useLocation } from 'react-router-dom';
function App() {
    return (
        <div className="App">
            <header className="App-header">

            <img
          src={Logo2} // Örnek bir resim URL'si
          alt="Resim"
          className="w-44 h-44 object-contain mb-6 rounded-md mx-auto" // mx-auto: yatayda ortalar
        />

<div className="relative">
          <img
            src={Logo}
            alt="Resim"
            className="w-full mb-6 rounded-md"
          />
          <Link to='https://panel.meramgelisim.com/Login'
            className="bg-[#ffd900] text-black py-2 px-4 rounded-md absolute bottom-40 right-52"
          >
         BAŞVUR
          </Link>
        </div>
                <div>

                    <div className='m-12' style={{ display: 'flex', flexDirection: 'row',alignItems:'center' }}>
                        <p className="text-gray-600" style={{width:'70%'}}> Bilgisayar oyunları, günümüzde özellikle mobil platformların ve internet teknolojilerinin gelişmesiyle birlikte tüm dünyada olduğu gibi ülkemizde de oldukça yaygınlaşmış, cinsiyet ve yaş ayırt etmeksizin geniş kitleler tarafından tüketilen popüler bir mecraya dönüşmüştür.

                            Geçtiğimiz 30 yıl içinde global anlamda önemli endüstrilerden birine dönüşmüş olan dijital oyun sektörü, son 10 yılda dijital dağıtım ağları sayesinde gerçek anlamda sınır tanımayan bir nitelik kazanmıştır. Devamlı dönüşüm halinde olan sektör gerek teknoloji gerekse de tasarım anlamında devamlı kendini yenilemektedir.

                            Bu durumun sonucunda ortaya çıkmış olan yüksek rekabet ortamında Türkiye oyun endüstrisinin yaratıcı ve yenilikçi oyun fikir ve uygulamaları ile küresel başarı kazanacağı görüşüyle hareket eden Meram Belediyesi, Meram Gelişim Akademisi kapsamında Dijital Oyun Tasarım Atölyesi ile hizmet verecektir.

                            MEGA Dijital Oyun Tasarım Atölyesi’nde hayali oyun tasarlamak olan çocuklara ve gençlere, oyun üretiminin tüm aşamaları hem teorik hem de pratik olarak verilecek, katılımcılara global bir sektörde hedeflerini gerçekleştirme imkânı sunulacaktır.</p>
                        <img
                            src={Logo3} // Örnek bir resim URL'si
                            alt="Resim"
                            style={{ width: '30%' }}
                        />
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
