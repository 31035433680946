import React, { Fragment } from 'react';
import Contacthelper from './Contacthelper';

import axios from 'axios';
import Navbar from "./Navbar2";

class Content extends Contacthelper {
    constructor() {
        super();
       this.state = { 
        contactDetail:{}
    }
}

recaptchaRef = React.createRef(null);


   async componentDidMount() {
       await this._getContact()
   }

   _getContact = async () => {
    try{

    await axios.get(`https://buharaapi.stechomeyazilim.info:5210/getContact/select/`)
     .then(res => {
         this.setState({contactDetail: res.data[0]})                    
         })
   }
   catch (error) {
     
     console.log("_getContact" + error)
   }
}

    
_handleSubmit = async (e) => {
    e.preventDefault();

    const captchaToken = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();
    
    const res = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${"6LcyxIYkAAAAABj3DAD7U33_m6Ui1QfLvNF7B-o2"}&response=${captchaToken}`
      );
      
      // Extract result from the API response
      if(res.data.success){
        console.log('Human');
      }
       
      else{
        console.log('BOT!!!');

      }

  }

    render() {
        return (
            
          <div className='w-full h-full'>
          <Navbar />

                <div className="max-w-full mt-36 w-full h-screen  justify-center items-center text-center">

                      
                       <text className='font-[Gotham-Regular]'>
                        
                        
Mega olarak, kullanıcılarımızın hizmetlerimizden güvenli ve eksiksiz şekilde faydalanmalarını sağlamak amacıyla sitemizi kullanan kişilerin gizliliğini korumak için çalışıyoruz. İşbu politika ile çerezlerin ne olduğunu ve çerezleri hangi doğrultuda kullandığımızı, kullandığımız çerezlerin türlerini, çerezleri kullanarak topladığımız bilgilerin nasıl kullanıldığını açıklarız. Bu çerezlerin kullanımı başta 6698 sayılı Kişisel Verilerin Korunması Kanunu ( KVK Kanunu ) olmak üzere tabi olduğumuz mevzuata uygun şekilde gerçekleştirilmektedir.

ÇEREZ NEDİR ?

Çerez,  küçük bilgileri saklamak için kullanılan küçük metin dosyalarıdır. Web sitesi tarayıcınıza yüklendiğinde, çerezler cihazınızda saklanır. Çerezler sayesinde internet sitesi tercihleriniz hatırlanabilir, oturumunuzun açık tutulması sağlanabilir ya da size ilgilendiğiniz içerik sunulabilir.

Çerezler mobil cihazlarda depolanma süreleri ve kimin tarafından yerleştirildikleri gibi kriterlere göre farklı türlere ayrılmaktadır. Bu bağlamda çerez türleri Kalıcı Çerezler, Oturum Çerezleri, Birinci Taraf Çerezleri ve Üçüncü Taraf Çerezler olarak sıralanabilir.

SİTEMİZDE ÇEREZ KULLLANILMASININ AMAÇLARI NELERDİR?

-	Site’nin ve uygulamanın çalışması için gerekli temel fonksiyonları gerçekleştirmek. Örneğin, oturum açan üyelerin Site’de farklı sayfaları ziyaret ederken tekrar şifre gerek kalmaması.
-	Site’nin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan hizmetleri geliştirmek, 
-	Site’nin iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek,
-	Site’nin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini sağlamak.
-	Doğrudan ve dolaylı pazarlama faaliyetleri kapsamında kullanmak
SİTEMİZDE KULLANILAN ÇEREZLER
Teknik Çerezler	Teknik çerezler ile Site’nin çalışması sağlanmakta, internet sitesinin çalışmayan sayfaları ve alanları tespit edilmektedir.
Oturum Çerezi: Oturum çerezleri oturumun sürekliliğinin sağlanması amacıyla kullanılır. 
Güvenlik Çerezi: Güvenlik çerezler, güvenlik kontrolleri için kullanılır.

Flash Çerezleri	Site’de yer alan görüntü veya ses içeriklerini etkinleştirmek için kullanılan çerez türleridir.

İşlevsellik ve Tercih 
Çerezleri	Sizlerin site ve uygulama üzerindeki tercihlerini ve seçimlerinizi hatırlayarak sitemizde sunulan hizmetlerin sizin için kişiselleşmesini sağlamaktadır. Örneğin sitemiz üzerindeki dil seçiminizi veya bir metin okurken seçmiş olduğunuz font boyutunu hatırlamamızı sağlar.
Dil Çerezi : Kullanıcının seçtiği dili kaydeder ve buna uygun seçenekler sunar.
Mobil Çerezi: Eğer kullanıcı Siteyi mobil bir cihazdan ziyaret ediyorsa, ana web sitesini göstermek için kullanılır. Kaynak site kullanıcının tercihlerinin daha iyi anlaşılabilmesi için kaydedilir.


Analitik Çerezler	Analitik çerezler ile Site’yi ziyaret edenlerin sayıları, Site’de görüntülenen sayfaların tespiti, Site ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi analitik sonuçların üretimini sağlayan çerezlerdir.


ÇEREZ KULLANIMININ ENGELLENMESİ

Veri sahipleri, tarayıcı ayarlarını değiştirerek çerezlere ilişkin tercihlerini kişiselleştirme imkanına sahiptir. Eğer kullanılan tarayıcı bu imkânı sunmaktaysa, tarayıcı ayarları üzerinden Çerezlere ilişkin tercihleri değiştirmek mümkündür. Böylelikle, tarayıcının sunmuş olduğu imkanlara göre farklılık gösterebilmekle birlikte, veri sahiplerinin çerezlerin kullanılmasını engelleme, çerez kullanılmadan önce uyarı almayı tercih etme veya sadece bazı Çerezleri devre bırakma ya da silme imkanları bulunmaktadır.

Çerezlere ilişkin tercihlerin, kullanıcının Platform’a erişim sağladığı her bir cihaz özelinde ayrı ayrı yapılması gerekebilecektir.

Farklı tarayıcılar için izlenmesi gereken adımlar aşağıdaki gibidir. Eğer tarayıcınız bahsi geçen tarayıcılardan farklı ise; ilgili tarayıcının yardım veya destek sayfasını inceleyebilirsiniz.
 
Google Chrome   
https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr
 
Internet Explorer
https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies

 
Yandex
https://yandex.com.tr/support/browser-classic/personal-data-protection/cookies.xml
 
Mozilla Firefox                                                           
https://support.mozilla.org/tr/kb/cerezleri-silme-internet-sitelerinin-bilgilerini-kaldirma
 
Safari
https://support.apple.com/kb/PH21411?locale=tr_TR

Kişisel veri sahipleri olarak aşağıda belirtilen haklarınıza ilişkin taleplerinizi Kişisel Veri Sahipleri Tarafından Hakların Kullanılması başlığı altında belirtilen yöntemlerle Şirket’e iletmeniz durumunda talepleriniz Şirketimiz tarafından mümkün olan en kısa sürede ve her halde 30 (otuz) gün içerisinde değerlendirilerek sonuçlandırılacaktır.
Kanun’un 11. maddesi uyarınca kişisel veri sahibi olarak aşağıdaki haklara sahipsiniz:
•	Kişisel verilerinizin işlenip işlenmediğini öğrenme,
•	Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
•	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
•	Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
•	Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
•	Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
•	İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
•	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.





                       </text>
                   
                </div>

                </div>
           
        );
    }
}

export default Content;