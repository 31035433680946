// src/App.js

import React from 'react';
import Logo from "../../assets/logoo.jpeg";
import Logo2 from "../../assets/logom.png";
import Logo3 from "../../assets/altimage.png";
import { Link, useLocation } from 'react-router-dom';
function App() {
    return (
        <div className="App">
            <header className="App-header">

            <img
          src={Logo2} // Örnek bir resim URL'si
          alt="Resim"
          className="w-44 h-44 object-contain mb-6 rounded-md mx-auto" // mx-auto: yatayda ortalar
        />

<div className="relative">
          <img
            src={Logo}
            alt="Resim"
            className="w-full mb-6 rounded-md"
          />
          <Link to='https://panel.meramgelisim.com/Login'
            className="bg-[#ffd900] text-black py-2 px-4 rounded-md absolute bottom-40 right-52"
          >
         BAŞVUR
          </Link>
        </div>
                <div>

                    <div className='m-12' style={{ display: 'flex', flexDirection: 'column',alignItems:'center' }}>
                        <p className="text-gray-600" style={{width:'70%',marginBottom:20}}> E-spor insanların bilgi ve teknolojileri kullanımı esnasında zihinsel ve fiziksel becerilerini eğittiği ve geliştirdiği spor faaliyet alanıdır. Bunun dışında, okçuluk sporunun gerektirdiği reaksiyon zamanını, beysboldaki gibi refleks hareketleri veya satranç sporunda olduğu gibi düşünsel öğeler içermesinden dolayı e-sporu zihinsel ve fiziksel çabaları kapsayan yeni nesil bir spor branşı olarak açıklamıştır.

Bu tanımlardan yola çıkarak e-sporun fiziksel ve zihinsel unsurları içeren rekabetçi bir müsabaka olmasından dolayı spor kapsamına girmesine sebep olmuştur. Aynı zamanda oyunların teknik, taktik, koç (antrenör) unsurunu içermesi ve turnuvaya hazırlık için antrenman yapılması; hızlı karar verebilme ve koordinasyon becerisinin oyundaki başarıyı olumlu yönde etkilemesi spor ile ortak noktalarını oluşturmaktadır.

Tüm dünyada hızlı gelişme gösteren, ciddi gelirlere ve izleyici sayısına ulaşan e-spor; ülkemizde de serbest zaman etkinliği, amatör ve profesyonel bir şekilde yapılmaktadır. Türk e-sporuna getireceği hareketlilik, sağlayacağı istihdam ve yatırımlar Gençlik ve Spor Bakanlığı tarafından da fark edilmiş ve 24.04.2018 tarihinde Türkiye E-spor Federasyonu kurulmuştur. Ülkemizde 30 milyondan fazla kişi farklı dijital platformlar üzerinden dijital oyunlar oynamakla birlikte federasyona kayıtlı 2200 lisanslı e-sporcu bulunmaktadır.

Türkiye’de e-spor, 850 milyon ile 950 milyon dolarlık bir pazar oluştururken, global ölçekte ise e-spor pazarının, 2023 sonunda 3 milyar dolar büyüklüğe, 2025 sonunda 5 milyar dolara ulaşması beklenmektedir.

Bu kapsamda; Türkiye’de Konya’yı Meram öncülüğünde dijital oyun ve e-spor pazarının ciddi bir parçası hâline getirmek, yeni iş alanları ve girişimler oluşturmak, şehrimizi uluslararası alanda marka olabilecek oyunlar, e-sporcular, e-spor takımları ve e-spor etkinlikleri çıkarabilecek pozisyona getirmek amacıyla Meram Gelişim Akademisi bünyesinde bir E-Spor Merkezi Projesi hazırlanmıştır.    
</p>   <img
                            src={Logo3} // Örnek bir resim URL'si
                            alt="Resim"
                            style={{ width: '30%' }}
                        />
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
