// src/App.js

import React from 'react';
import Logo from "../../assets/yen.png";
import Logo2 from "../../assets/yeninesillogo.png";
import Logo3 from "../../assets/yenii.png";
import { Link, useLocation } from 'react-router-dom';
function App() {
    return (
        <div className="App">
            <header className="App-header">

            <img
          src={Logo2} // Örnek bir resim URL'si
          alt="Resim"
          className="w-44 h-44 object-contain mb-6 rounded-md mx-auto" // mx-auto: yatayda ortalar
        />

<div className="relative">
          <img
            src={Logo}
            alt="Resim"
            className="w-full mb-6 rounded-md"
          />
          <Link to='https://panel.meramgelisim.com/Login'
            className="bg-[#ffd900] text-black py-2 px-4 rounded-md absolute bottom-40 right-52"
          >
         BAŞVUR
          </Link>
        </div>
                <div>

                    <div className='m-12' style={{ display: 'flex', flexDirection: 'column',alignItems:'center' }}>
                        <p className="text-gray-600" style={{width:'70%',marginBottom:20}}> Bilgisayar oyunları, günümüzde özellikle mobil platformların ve internet teknolojilerinin gelişmesiyle birlikte tüm dünyada olduğu gibi ülkemizde de oldukça yaygınlaşmış, cinsiyet ve yaş ayırt etmeksizin geniş kitleler tarafından tüketilen popüler bir mecraya dönüşmüştür.

                        Merkezde; etkinlik alanlarında ürün ve hizmetlerin geliştirildiği, gençlerin interaktif çalışmalar ile zihinsel, bedensel, bilişsel ve sosyal yönden gelişimini sağlayan bir uygulama alanı oluşturulmaktadır. Hafta içi milli eğitime bağlı okul gruplarına hafta sonu serbest ziyaretçilere hizmet verilecek, akıl zeka oyunları, teknoloji ve sanat etkinliklerinin yanı sıra, dil gelişimini destekleyen atölyelerle birlikte liderlik ve kariyer etkinlikleri düzenlenecektir.

Meram Belediyesi ve KOP İdaresi Başkanlığı işbirliğinde bölgede bir ilk ve örnek bir proje daha hayata geçiriliyor. Meram Belediyesi MEGA çatısı altında hayata geçirilecek proje kapsamında ilçeye ‘Meram Belediyesi Yeni Nesil KOP Öğrenme Merkezi’ kazandırılacak. Bünyesinde, Cebir Atölyesi, Astronomi - Havacılık ve Uzay Atölyesi, Kariyer Atölyesi, Dil Atölyesi, Mimari ve Sanat Atölyesi, ilim ve Fen Atölyesi, Etüt Salonu, Çocuk ve Genç Kütüphanesi, Maker Atölyesi ve e-spor Atölyesi bulunacak olan ‘Meram Belediyesi Yeni Nesil KOP Öğrenme Merkezi’nde eğitim alan Meram gençliği, bilişim teknolojileri, inovasyon ve tasarım odaklı etkinlik alanlarında ürün ve hizmetlerini geliştirebilecek ve interaktif çalışmalar sergileyebilecekler. Onların zihinsel,  bedensel, bilişsel ve sosyal yönden gelişimini sağlayan bir uygulama alanının oluşturulmasının hedeflendiği projenin imzaları Meram Belediye Başkanı Mustafa Kavuş ve KOP Bölge Kalkınma İdaresi Başkanı Mahmut Sami Şahin tarafından atıldı. </p>           <img
                            src={Logo3} // Örnek bir resim URL'si
                            alt="Resim"
                            style={{ width: '30%' }}
                        />
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
