import React, { useState, useEffect } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from 'axios';
import Navbar from "../Navbar2";
import Footer from "../Footer";
const Content = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getEventMega/select/')
      .then(res => {
        setEvents(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  return (
    <div>
              <Navbar />
      {/* Bileşen içeriği */}
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth'
        weekends={false}
        events={events}
        eventContent={renderEventContent}
      />

<Footer />
    </div>
  );
}

export default Content;
