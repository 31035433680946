import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Navbar from "../Navbar2";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { faEnvelope, faLocation, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer";
import Slider from 'react-slick';
import L from 'leaflet'; // Leaflet kütüphanesi
import 'leaflet/dist/leaflet.css'; // Leaflet CSS dosyası
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; // Gerekli bileşenler
import { FaContao } from 'react-icons/fa';


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Content = (isOpen, onClose) => {
  const [refData, setrefData] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);

  const latitude = 37.8485267; // Örnek enlem
  const longitude = 32.4738541; // Örnek boylam

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getReferancesMega/select/')
      .then(res => {
        setrefData(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const handleImageClick = (image) => {
    setCurrentImage(image);
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      onClose();
    }
  };
  const popupRef = useRef(null);
  const imgUrl = 'https://megasubdomain.stechomeyazilim.info/';


  useEffect(() => {
    if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
    } else {
        document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    };
}, [isOpen]);

const handlePopupClose = () => {
    setCurrentImage(null);
};

  const [newsData, setData] = useState([]);


  useEffect(() => {
    axios.get('https://getjob.stechomeyazilim.info:5101/getAboutMega/select/')
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const date = new Date(); // Şu anki tarih ve saat bilgisini al

    try {
      const response = await axios.post('https://getjob.stechomeyazilim.info:5101/postContactMessageMega/send', {
        NameSurname: formData.get('name'),
        MailAdress: formData.get('email'),
        Subject: formData.get('konu'),
        Contact_Message_Messages: formData.get('message'),
        CreatedDateTime: date.toISOString() // ISO formatında tarih bilgisini ekler

      });

      toast.success('Mesajınız başarıyla gönderildi.');
    } catch (error) {
      console.error(error);

      toast.error('Bir hata oluştu, mesaj gönderilemedi.');
    }
  };

  return (
    <div>
    <Navbar />


    

      <div className=" mt-20  bg-gradient-to-br from-[#5BC2EB] to-[#5BC2EB] w-full py-12 text-white text-center shadow-2xl rounded-lg">
    <h1 className="text-4xl font-extrabold p-2 flex items-center justify-center drop-shadow-md">
      İLETİŞİM
    </h1>
    <div className="mt-6 flex justify-center space-x-8">
      <Link to={"/"} className="font-bold text-white py-3 px-6 rounded-md transition-all duration-300 transform hover:scale-110 hover:bg-white hover:text-[#EB738A] shadow-md">Anasayfa</Link>
    </div>
  </div>



    {newsData[0] && (
  <div className="flex justify-center items-center flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 mt-8">

    {/* Adres Kartı */}
    <div className="w-60 h-32 p-6 bg-white rounded-lg flex flex-col items-center justify-center shadow-lg transition-transform transform hover:scale-105">
    
      <p className="text-[#3DB2E0] font-semibold text-center">{newsData[0].Contact_Adress}</p>
    </div>

    {/* Telefon Kartı */}
    <div className="w-60 h-32 p-6 bg-white rounded-lg flex flex-col items-center justify-center shadow-lg transition-transform transform hover:scale-105">
      
      <p className="text-[#3DB2E0] font-semibold text-center">{newsData[0].Contact_TelephoneNumber}</p>
    </div>

    {/* E-posta Kartı */}
    <div className="w-60 h-32 p-6 bg-white rounded-lg flex flex-col items-center justify-center shadow-lg transition-transform transform hover:scale-105">
     
      <p className="text-[#3DB2E0] font-semibold text-center">{newsData[0].Contact_EmailAdress}</p>
    </div>
  </div>
)}
<div className="border-2 border-[#f3f3f3] rounded-lg mx-5 md:mx-20 my-10 shadow-lg overflow-hidden">
  <div className="bg-gradient-to-r p-5 from-[#5BC2EB] via-[#006890] to-[#5BC2EB] flex flex-col items-center">
    <h6 className="text-light-green text-[30px] text-center font-[Lato-Bold] text-white">İletişim Formu</h6>
    <h3 className="text-light-green text-[10px] text-center font-[Lato-Medium] text-white">MERAM GELİŞİM AKADEMİSİ</h3>
  </div>

  <form onSubmit={handleSubmit} className="p-6 bg-white rounded-b-lg shadow-inner">
    <div className="mb-4">
      <input
        type="text"
        placeholder="Adınız Soyadınız"
        name="name"
        className="px-4 py-3 placeholder-gray-400 text-gray-600 bg-white rounded-md border border-gray-300 shadow-sm focus:ring-2 focus:ring-[#3DB2E0] w-full transition duration-150"
        required
      />
    </div>

    <div className="mb-4">
      <input
        type="email"
        placeholder="Eposta"
        name="email"
        className="px-4 py-3 placeholder-gray-400 text-gray-600 bg-white rounded-md border border-gray-300 shadow-sm focus:ring-2 focus:ring-[#3DB2E0] w-full transition duration-150"
        required
      />
    </div>

    <div className="mb-4">
      <input
        type="text"
        placeholder="Konu"
        name="konu"
        className="px-4 py-3 placeholder-gray-400 text-gray-600 bg-white rounded-md border border-gray-300 shadow-sm focus:ring-2 focus:ring-[#3DB2E0] w-full transition duration-150"
        required
      />
    </div>

    <div className="mb-4">
      <textarea
        placeholder="Mesajınız"
        name="message"
        className="px-4 py-3 placeholder-gray-400 text-gray-600 bg-white rounded-md border border-gray-300 shadow-sm focus:ring-2 focus:ring-[#3DB2E0] w-full h-32 transition duration-150"
        required
      />
    </div>

    <div className="flex justify-center">
      <button
        className="bg-[#3DB2E0] w-full md:w-40 text-white font-bold uppercase text-sm px-6 py-3 rounded-md shadow-lg hover:bg-[#006890] transition duration-200 transform hover:scale-105"
        type="submit"
      >
        Gönder
      </button>
    </div>
  </form>
</div>


    {/* Harita Bölümü */}
    {newsData[0] && (
      <div className="w-full h-80 mt-10 mb-10">
      <MapContainer center={[latitude, longitude]} zoom={13} className="h-full">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[latitude, longitude]}>
            <Popup>
              Bu konumda bir yer var.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    )}

    {refData.length !== 0 && (
      <div className='bg-gradient-to-b from-[#006890] flex flex-col p-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center ' }}>
        <p className='mt-5 font-[Lato-Bold] text-center text-3xl underline text-[white]'>İŞ BİRLİKLERİM</p>
        <div className="flex w-full rounded-lg justify-center items-center flex-col p-5">
          <Slider className="w-5/6 p-5" {...settings}>
            {refData.map((image, index) => (
              <div className='flex flex-col items-center' key={index}>
                <div className='flex flex-col mr-5 items-center'>
                  <img
                    src={imgUrl + image.Referances_LogoImage}
                    className='object-contain h-48 w-48 mx-2 cursor-pointer hover:opacity-75'
                    onClick={() => handleImageClick(imgUrl + image.Referances_LogoImage)}
                    alt={image.Referances_Name}
                  />
                  <p className='text-[#006890] font-[Lato-Bold]'>{image.Referances_Name}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )}

    {currentImage && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={handlePopupClose}>
        <img src={currentImage} alt="Büyütülmüş" className="max-w-full max-h-full" />
      </div>
    )}

    <ToastContainer />
    <Footer />
  </div>
  );
};

export default Content;
